import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
    const navigate = useNavigate();

    const navigateToNoteBook = () => {
        navigate('/notebook');
    };

    return (
    <div>
        <h1>Welcome to the Zhihan's EHome</h1>
        <button onClick={navigateToNoteBook}>Go to NoteBook</button>
    </div>
    );

};

export default Home;